<template>
      <div class="bottom">
          <div class="relation">
          <!-- 烟台八角国际会展中心 -->
          <!-- <p>{{ $t("language.ExhibitionCore") }}</p> -->
          <p>{{ $t("language.Telephone") }}  <span style="margin:0 5px 0 3px;">:</span>  0535-6959157</p>
          <!-- <p>投诉电话 <span style="margin:0 5px 0 3px;">:</span>  0535-6939167</p> -->
          
          <!-- 地址: 烟台经济技术开发区八角湾 -->
          <p v-html="$t('language.shandongyantai')"></p>
          <p>{{$t("language.Mailbox")}}  <span style="margin:0 5px 0 3px;">:</span> ytbjw@ybicec.com</p>
        </div>
        
          <!-- 关注 -->
          <p  class="relationequipment">
            <img src="../assets/image/底部导航-微博.png" alt="" />
            <img src="../assets/image/底部导航-微信.png" alt="" style="margin-left:0.3rem" /> 
          </p>
      
          <!-- <p v-html="$t('language.friendship')"></p>
          <p v-html="$t('language.Developmentofthecompany')"> -->
              <!-- 烟台业达国际会展有限公司 烟台业达文旅有限公司 -->
            <!-- </p> -->
          <p v-html="$t('language.bq')">
              
              <!-- 版权所有 烟台八角湾国际会展中心 -->
          </p>
          <p class="bq" v-html="$t('language.bqcontent')">
              <!-- COPYRIGHT © 2021 烟台八角湾国际会展中心. ALLRIGHTS RESEVRED | 鲁ICP备15003446号-1 -->
            </p>
            <div class="bq">

                <img src="../assets/image/beian.png" alt=""  style="width:12px;height:12px;"/>
          <a style="color: #ffffff;margin-left: 4px;" href="https://beian.mps.gov.cn/#/query/websearch?code=37069302889046" rel="noreferrer" target="_blank">鲁公网安备37069302889046</a> 
            </div>

      </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .bottom{
        width: 100%;
        background: #292929;
        color: #fff;
        box-sizing: border-box;
        color: #999999;
        line-height: 0.3rem;
        padding: 0.3rem 0;
    }
    .bq{
        width: 75%;
        text-align: center;
        margin: 0 auto;
    }
    .bottom p:nth-of-type(1){
        width: 80%;
        text-align: center;
        margin: 0 auto;
    }
    .bottom p:nth-of-type(2){
        width: 80%;
        text-align: center;
        margin: 0 auto;
    }
    .bottom p:nth-of-type(3){
        width: 80%;
        text-align: center;
        margin: 0 auto;
    }
    .bottom p:nth-of-type(4){
        width: 80%;
        text-align: center;
        margin: 0 auto;
    }
    .relationequipment{
        height: 0.2rem;
    }
</style>