<template>
  <div class="navbox">
      <div class="navs" :style="{position: relative}">
            <img class="logo" src="../assets/image/LOGO.png" @click="goindexspage" alt="">
            <div class="navsright">
                <p @click="showcard"><img src="../assets/image/底部导航-微信.png" alt="">
                <span v-show="showcardS" class="showcard"><img class="imgs" src="../assets/image/201628491340_.pic_hd.png" alt="">
                <span style="color: #91278f;width: 100%;display: block;text-align: center;">二维码</span>
                </span>
                </p>
                <!-- <p @click="ileqh">EN
                    <span class="ile" :style="{height:heights,}">
                        <span @click="iles('中文','en-US')" v-if="this.zeiles">中文</span>
                        <span @click="iles('English','zh-CN')" v-if="this.zeiles">English</span>
                    </span>
                </p> -->
                <p><van-icon name="wap-nav"  @click="shownav"/></p>
            </div>
        </div>
    <van-swipe class="my-swipe" :autoplay="3000" loop :show-indicators="false" touchable>
        <van-swipe-item v-for="(item,index) in background" :key="index"><img class="bac" :src="item.image" alt="">
             
        </van-swipe-item>
    </van-swipe>
    <van-collapse v-model="activeNames" :style="{height: heighta}" class="show">
       <div v-if="show">
            <van-collapse-item v-for="(item,index) in pulldown" :key="index" :title="$t(`language.${item.title}`)" :name="item.name" class="showitem">
                <div class="contentitem" v-for="(ite,indexs) in item.content" :key="indexs" @click="next(ite)">{{$t(`language.${ite.titles}`)}}</div>
            </van-collapse-item>
       </div>
    </van-collapse>
  </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
    data() {
        return {
            background:[
              {image:require('../assets/image/banners1.png')},
            //   {image:require('../assets/image/cbc627be8ed6f28e1d956c08a1d28a1.jpg')},
              {image:require('../assets/image/lADPDgtYx81R3yrNA5bNB4A_1920_918.jpg')}
            ],
            show:false,
            activeNames: ['0'],
            heighta:0,
            pulldown:[
                {title:"AboutUs",name:"1",content:[
                    {title:"鲜美烟台",titles:"DeliciousYantai",router:"/deliciousyt"},
                    {title:"活力开发区",titles:"DynamicDevelopmentZone",router:"/deliciousyt"},
                    {title:"公司介绍",titles:"CompanyIntroduction",router:"/deliciousyt"},
                    {title:"联系我们",titles:"ContactUs",router:"/deliciousyt"},
                ]},
                {title:"Thepavilionguide",name:"2",content:[
                     {title:"展馆概况",titles:"ExhibitionHallSurvey",router:"/exhibition"},
                     {title:"展览场地",titles:"exhibitionGround",router:"/exhibition"},
                     {title:"会议中心",titles:"ConferenceCenter",router:"/exhibition"},
                     {title:"精美组图",titles:"ExquisiteGroupPictures",router:"/exhibition"},
                     {title:"VR导览",titles:"vrView",router:"/exhibition"},
                ]},
                {title:"Showthedynamic",name:"3",content:[
                    {title:"新闻动态",titles:"NewInformation",router:"/TradeShowTrends"},
                    {title:"精彩瞬间",titles:"WonderfulMoment",router:"/TradeShowTrends"},
                    {title:"展会排期",titles:"ExhibitionScheduling",router:"/TradeShowTrends"},
                    {title:"通知公告",titles:"NoticeNotice",router:"/TradeShowTrends"},
                ]},
                {title:"exhibitionservices",name:"4",content:[
                    {title:"餐饮服务",titles:"FoodAndBeverages",router:"/ExhibitionServices"},
                    {title:"展具租赁",titles:"ExhibitionEquipmentRental",router:"/ExhibitionServices"},
                    {title:"广告服务",titles:"AdvertisingServices",router:"/ExhibitionServices"},
                    {title:"搭建服务",titles:"BuildService",router:"/ExhibitionServices"},
                ]},
                {title:"datadownload",name:"5",content:[
                     {title:"展馆平面图",titles:"HallPlan",router:"/datadownload"},
                     {title:"场地动线图",titles:"SiteDynamicLineMap",router:"/datadownload"},
                     {title:"宣传手册",titles:"Brochure",router:"/datadownload"},
                     {title:"管理规定",titles:"ManagementRegulations",router:"/datadownload"},
                     {title:"报批文件",titles:"ApprovalDocument",router:"/datadownload"},
                ]},
                {title:"Peripheralservices",name:"6",content:[
                     {title:"交通指南",titles:"TrafficGuide",router:"/traffic"},
                     {title:"停车信息",titles:"ParkingInformation",router:"/traffic"},
                     {title:"周边酒店",titles:"SurroundingHotels",router:"/traffic"},
                     {title:"旅游景点",titles:"ScenicSpot",router:"/traffic"},
                ]},
            ],
            showcardS:false,
            relative:"absolute",
            heights:"0",
            zeiles:"",
            long:""
        }
    },
    created(){
        console.log("aa")
window.localStorage.setItem("locale","zh-CN")
    },
    methods: {
        // 中英文翻译
        iles(val){
            if(val == '中文'){
                Dialog.confirm({
                    title: '提示',
                    message: `确定切换为${val}吗?`,
                }).then(() => {
                    window.localStorage.setItem("locale","zh-CN")
                    this.long = "zh-CN" 
                    this.$i18n.locale = this.long; //关键语句
                    history.go(0)
                }).catch(()=>{
                    console.log('取消切换');
                })
            }else{
                Dialog.confirm({
                    title: '提示',
                    message: `Are you sure you want to switch to ${val}?`,
                }).then(() => {
                    window.localStorage.setItem("locale","en-US")
                    this.long = "en-US" 
                    this.$i18n.locale = this.long; //关键语句
                    history.go(0)
                }).catch(()=>{
                    console.log('取消切换');
                })
            }
           
        },
        ileqh(){
            this.heights == '0'?this.heights = '0.6rem':this.heights = '0'
            this.showcardS = false
            if(this.zeiles == false){
                setTimeout(()=>{
                    this.zeiles = true
                },300)
            }else{
                this.zeiles = false
            }
        },
        shownav(){
            if(this.heighta != 0){
                setTimeout(()=>{
                    this.show = false
                },300)
            }else{
                setTimeout(()=>{
                    this.show = true
                },500)
                this.heights = '0'
                this.zeiles = false
                this.showcardS = false
            }
            this.heighta == 0 ? this.heighta = "calc(100% - 0.60rem)" : this.heighta = 0
        },
        // 显示二维码
        showcard(){
            this.showcardS = !this.showcardS
             this.heights = '0'
             this.zeiles = false
        },
        goindexspage(){
            this.$router.push('/')
        },
        next(val){
            if (val.title == "VR导览") {
                window.location.href = "http://www.ybicec.com/vr/"
            } else {
                if(this.$route.path != val.router){
                    this.$router.push({path:val.router,query:{path:val.title}})
                }else{
                    this.$router.push({path:val.router,query:{path:val.title}})
                    history.go(0)
                }
            }
                
        }
    },
}
</script>

<style scoped>
    /* .van-dialog >>> .van-dialog__confirm{
        color: #000 !important;
    } */
    .showcard{
        width: 1.8rem;
        height: 1.87rem;
        display: block;
        background: #fff;
        position: absolute;
        top: 0.6rem;
    }
    .showcard .imgs{
        width: 1.50rem !important;
        height: 1.50rem !important;
        display: block !important;
        margin: 0.15rem auto 0 !important;
    }
    .navbox{
        width: 100%;
        height: 100%;
    }
    .my-swipe{
        width: 100%;
        height: 1.793rem;
        position: relative;
        margin-top: 0.6rem;
    }
    .my-swipe .van-swipe-item .bac{
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;top: 0;
    }
    .navs{
        width: 100%;
        height: 0.60rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        left: 0;top: 0px;
        padding: 0.1rem;
        box-sizing: border-box;
        z-index: 100;
        background: #000;
    }
    .logo{
        width: 2.0rem;
        height: auto;
    }
    .navsright{
        display: flex;
    }
    .navsright{
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #fff;
    }
    .navsright p:nth-of-type(1){
        width: 0.18rem;
        height: 0.18rem;
        border: 0.02rem solid #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .navsright p:nth-of-type(1) img{
        width: 0.14rem;
        height: 0.13rem;
    }
    .navsright p:nth-of-type(2){
       font-size: 0.20rem;
       padding-top: 0.04rem;
       position: relative;
    }
    .ile{
        position: absolute;
        display: inline-block;
        width: 0.8rem;
        height: 0rem;
        transition: 0.5s;
        background: #fff;
        left: -0.25rem;
        top: 0.43rem;
    }
    .ile span{
        color: #000;
        font-size: 0.13rem;
        display: inline-block;
        width: 100%;
        height: 50%;
        line-height: 0.3rem;
        text-align: center;
    }
    .navsright p:nth-of-type(3) {
        font-size: 0.26rem;
        padding-top: 0.07rem;
    }
    .show{
        width: 100%;
        height: 0;
        position: absolute;
        background: rgba(0, 0, 0, .7);
        left: 0;top: 0.6rem;
        transition: 1s;
        z-index: 1000;
    }
    .contentitem{
        width: 100%;
        height: 0.3rem;
        line-height: 0.3rem;
    }
    .showitem >>> .van-collapse-item__content{
        background: rgba(0, 0, 0, .7);
        color: #fff;
    }
    .showitem >>> .van-cell{
        background: rgba(0, 0, 0, .7);
        color: #fff;
    }
</style>